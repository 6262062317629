
@import url('https://fonts.googleapis.com/css?family=Raleway:700');

body {
  margin: 0;
  background: linear-gradient(90deg, #434647, #070707);
  width: 100vw;
  max-height: 70vh;
  background: black;
}

.square1 {
  background-color: #FF8776;
  height: 36vw;
  width: 36vw;
  border-radius: 20%;
  position: absolute;
  top: 18vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  animation: counterClockwise 3s infinite;
  opacity: 0.9;
  font-family: sans-serif;
  color: white;
  font-size:20px;
  font-weight: 600;
}

.square2 {
  background-color: #5576ED;
  height: 36vw;
  width: 36vw;
  border-radius: 20%;
  position: absolute;
  top: 18vh;
  left: 50vw;
  transform: translate(-50%, -50%) rotate(15deg);
  animation: clockwise 4s infinite;
  opacity: 0.9;
  font-family: sans-serif;
  color: white;
  font-size:20px;
  font-weight: 600;
}



@keyframes counterClockwise {
  50% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

@keyframes clockwise {
  30% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  
  70% {
    transform: translate(-50%, -50%) rotate(270deg);
  }
}

.letter {
  font-family: sans-serif;
  color: white;
  font-size:18px;
  font-weight: 600;
  position: absolute;
  top: 18vh;
  left: 65vw;
  transform: translate(-75%, -50%);
  opacity: 0.8;
}

.pl{
  width: 100vw;
  height: 90px;
  border-radius: 10px;
  background-color: red
}

.layoutHeader{
  color: black
}

.footer{
  width: 100vw;
  height: 63px;
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: row;
  background-color: black;
  justify-content: space-evenly;
  align-items: center;
  border: none
}

.square{
  width: 50px;
  height: 50px;
  top: 45px;
  left: 45px;
}

.header-main{
  display: flex;
  flex-direction: row;
}

.letters{
  font-size: 8px;
  top: 45px;
  left: 50px;
}

.footer-button{
  width: 13 0px;
  padding: 10px;
  height: 60px;
  background-color:black;
  color: white;
  font-size: 18px;
  margin: 0px;
  border: none;
}

.footer-button:disabled{
 color: grey;
}


.dashboard-container{
 width: 100vw;
 height: 70vh;
 margin-top: 20%;
 background-color:black;
 display: flex;
 flex-direction: column;
 overflow: hidden;
}

.profit-viewer{
  width: 100%;
  height: 80px;
  background: rgb(111, 113, 111);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  padding-left: 10px;
  border-radius: 10px;
}

.profit{
color: rgb(79, 233, 79);  
}

.loss{
  color: rgb(187, 16, 16)
  }

  .userContainer{
    background-color: black;
    width: 96%;
    height: 60px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    border-bottom: 1px solid grey;
    margin-top: 3px;
    padding: 5px;
    color: white;
  
  }

.username{
  flex: 1;
  max-width: 180px;
  margin-bottom: 18px;
}
  


.userViewer{
  overflow-y: scroll;
  overflow-x: hidden;
}

.icons{
  width: 45px;
  height: 45px;
}

.bubble {
  display: block; 
  position: absolute;
  cursor: pointer; 
  border-radius: 50%;
}

.bubble:hover:after {
  
}

.bubble:after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  display: block;
  top: 1px;
  left: 1px;
}

.bubble .bubble-outer-dot {
  margin: 1px;
  display: block;
  text-align: center;
  opacity: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite
}

.bubble .bubble-inner-dot {
 
  display: block;
  text-align: center;
  opacity: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite
}

.bubble .bubble-inner-dot:after {
  content: "";
  display: block;
  text-align: center;
  opacity: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite
}

@-webkit-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: .75
  }
  25% {
    transform: scale(1);
    opacity: .75
  }
  100% {
    transform: scale(2.5);
    opacity: 0
  }
}

@keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: .75
  }
  25% {
    transform: scale(1);
    opacity: .75
  }
  100% {
    transform: scale(2.5);
    opacity: 0
  }
}

@-moz-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: .75
  }
  25% {
    transform: scale(1);
    opacity: .75
  }
  100% {
    transform: scale(2.5);
    opacity: 0
  }
}

@-o-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: .75
  }
  25% {
    transform: scale(1);
    opacity: .75
  }
  100% {
    transform: scale(2.5);
    opacity: 0
  }
}


/*Center-div (Not part of the symbol)*/

#center-div {
  position: absolute;
  top: 20%;
  left: 24%;
  margin: auto;
  width: 14px;
  height: 14px;
}

.offline{
  background-color: rgba(255, 0, 0, 0.4);
}

.online{
  background-color: green;
}

