.model-container{
    width: 50vw;
    height: 180px;
    background: transparent;
    border-radius: 10px;
    padding: 30px;
    position: absolute;
    top: 36vh;
    left: 9vh;

}
.git{
    height: 130px;
}

.model-input{
    width: 95%;
    height: 35px;
    margin: 5px;
    font-size: 16px;
}


.glow-on-hover {
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    position: relative;
    border: 1px solid  #01b358;
    border-radius: 20px;
    background-color: transparent;
}

